/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
.change-col-box > div[data-v-fe319ed4] {
  padding: 5px;
  display: flex;
}
.change-col-box > div > label[data-v-fe319ed4] {
  text-align: right;
  padding-right: 8px;
  display: block;
  font-size: 16px;
  width: 45px;
}
