/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
#Right {
  min-width: 208px;
  padding: 32px 40px;
}
#Right .content {
  width: 100%;
  height: calc(100% - 48px);
  overflow: auto;
}
#Right .content .menus {
  margin-bottom: 12px;
}
#Right .content .menus > p {
  font-size: 16px;
  color: #303133;
  line-height: 22px;
  margin-bottom: 18px;
  font-weight: bold;
}
#Right .content .menus ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#Right .content .menus ul.level1 {
  width: 100%;
  height: 100%;
  max-width: 768px;
  -moz-column-gap: 16px;
       column-gap: 16px;
  margin: 0 auto;
}
#Right .content .menus ul.level1 > li {
  width: 196px;
  color: #1890ff;
  font-size: 14px;
  line-height: 20px;
  -moz-column-break-inside: avoid;
       break-inside: avoid;
  cursor: pointer;
  padding-bottom: 18px;
  padding-right: 18px;
}
#Right .content .menus ul.level1 > li > span {
  margin-bottom: 18px;
  display: inline-block;
  font-weight: bold;
}
#Right .content .menus ul.level1 > li > span:hover {
  color: #40a9ff;
}
#Right .content .menus ul.level1 ul.level2 li {
  padding-bottom: 18px;
}
#Right .content .menus ul.level1 ul.level2 li span {
  color: #606266;
}
#Right .content .menus ul.level1 ul.level2 li span:hover {
  color: #1890ff;
}
#Right .empty svg path {
  fill: unset;
}
