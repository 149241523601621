/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
.option-change-container[data-v-66b87af1] {
  width: calc(100% - 8px);
}
.option-change-box[data-v-66b87af1] {
  height: 38px;
  padding-bottom: 6px;
}
.option-change-box .option-delete-box[data-v-66b87af1] {
  margin-top: 3px;
  background: #ffe9e9;
  color: #f22;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  transition: all 0.3s;
}
.option-change-box .option-delete-box[data-v-66b87af1]:hover {
  background: #f22;
  color: #fff;
}
