/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
.notify {
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  display: inline;
  text-align: center;
}
.notify .ant-alert {
  display: inline-flex;
  align-items: center;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  transition: all 0.2s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
