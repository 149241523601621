/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
.waves-ripple {
  position: absolute;
  border-radius: 100%;
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.35) 100%, rgba(0, 0, 0, 0.15) 100%);
  background-clip: padding-box;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transform: scale(0);
  opacity: 1;
}
.waves-ripple.z-active {
  opacity: 0;
  transform: scale(2);
  transition: opacity 1.2s ease-out, transform 0.6s ease-out;
}
