/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
.kk-table-9136076486841527 {
  width: 100%;
  box-sizing: border-box;
  transition: all 0.3s;
  border-collapse: collapse;
}
.kk-table-9136076486841527 tr {
  transition: all 0.3s;
  border-collapse: collapse;
}
.kk-table-9136076486841527 tr td {
  box-sizing: border-box;
  transition: all 0.3s;
  padding: 12px 12px;
  border-collapse: collapse;
  vertical-align: top;
}
.kk-table-9136076486841527.bordered tr td {
  border: 1px solid #e8e8e8 !important;
}
.kk-table-9136076486841527.bright tr:hover > td {
  background: #e6f7ff;
}
.kk-table-9136076486841527.small tr td {
  padding: 8px 8px;
}
.kk-table-9136076486841527 .ant-row.ant-form-item {
  margin: 0 !important;
}
/*
 * @Description: 用于汉化编辑器
 * @Author: kcz
 * @Date: 2020-03-30 14:00:29
 * @LastEditors: kcz
 * @LastEditTime: 2020-03-30 14:04:41
 */
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-tooltip[data-mode='link']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-tooltip[data-mode='link']::before {
  content: '请输入链接地址:';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-tooltip.ql-editing a.ql-action::after,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: '保存';
  padding-right: 0px;
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-tooltip[data-mode='video']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-tooltip[data-mode='video']::before {
  content: '请输入视频地址:';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-label::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-label::before,
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-item::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '14px';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
  content: '10px';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
  content: '18px';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
  content: '32px';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-label::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-label::before,
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-item::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: '文本';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
  content: '标题1';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
  content: '标题2';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
  content: '标题3';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
  content: '标题4';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
  content: '标题5';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
  content: '标题6';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-font .ql-picker-label::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-font .ql-picker-label::before,
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-font .ql-picker-item::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: '标准字体';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
  content: '衬线字体';
}
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.form-designer-container-9136076486841527 .chinesization .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before,
.k-form-build-9136076486841527 .chinesization .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
  content: '等宽字体';
}
.form-designer-container-9136076486841527 {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.form-designer-container-9136076486841527 .ant-form-vertical .ant-form-item-required::before {
  display: inline-block;
}
.form-designer-container-9136076486841527 .header {
  width: 100%;
  height: 56px;
  text-align: center;
  background: #1890ff;
  color: #fff;
  line-height: 56px;
  font-size: 18px;
  font-weight: bold;
}
.form-designer-container-9136076486841527 .operating-area {
  border-bottom: 2px solid #ccc;
  font-size: 16px;
  text-align: left;
  height: 45px;
  line-height: 45px;
  padding: 0px 12px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.form-designer-container-9136076486841527 .operating-area a {
  color: #666;
  margin: 0 5px;
}
.form-designer-container-9136076486841527 .operating-area a.disabled,
.form-designer-container-9136076486841527 .operating-area a.disabled:hover {
  color: #ccc;
}
.form-designer-container-9136076486841527 .operating-area a:hover {
  color: #1890ff;
}
.form-designer-container-9136076486841527 .operating-area a > span {
  font-size: 14px;
  padding-left: 2px;
}
.form-designer-container-9136076486841527 .icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.form-designer-container-9136076486841527 .content {
  display: flex;
  height: 100%;
  flex: 1;
}
.form-designer-container-9136076486841527 .content.show-head {
  margin-top: 6px;
  height: calc(100% - 56px - 6px);
}
.form-designer-container-9136076486841527 .content.toolbars-top {
  margin-top: 6px;
  height: calc(100% - 45px - 6px);
}
.form-designer-container-9136076486841527 .content.show-head-and-toolbars-top {
  margin-top: 6px;
  height: calc(100% - 56px - 45px - 6px);
}
.form-designer-container-9136076486841527 .content aside {
  box-shadow: 0px 0px 1px 1px #ccc;
  width: 270px;
  height: 100%;
}
.form-designer-container-9136076486841527 .content aside.left {
  height: 100%;
  overflow: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.form-designer-container-9136076486841527 .content aside.left .ant-collapse-content-box {
  padding: 0;
}
.form-designer-container-9136076486841527 .content aside.left .ant-collapse {
  border: 0;
}
.form-designer-container-9136076486841527 .content aside.left .ant-collapse > .ant-collapse-item {
  border-color: #ccc;
}
.form-designer-container-9136076486841527 .content aside.left .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 7px 0 7px 40px;
}
.form-designer-container-9136076486841527 .content aside.left .ant-collapse > .ant-collapse-item > .ant-collapse-content {
  border-color: #ccc;
}
.form-designer-container-9136076486841527 .content aside.left ul {
  padding: 5px;
  list-style: none;
  display: flex;
  margin-bottom: 0;
  flex-wrap: wrap;
}
.form-designer-container-9136076486841527 .content aside.left ul li {
  border-radius: 0;
  border: 0;
  box-shadow: 1px 0 0 0 #ccc, 0 1px 0 0 #ccc, 1px 1px 0 0 #ccc, 1px 0 0 0 #ccc inset, 0 1px 0 0 #ccc inset;
  padding: 8px 12px;
  width: calc(50% - 6px);
  margin: 2.7px;
  height: 36px;
  line-height: 20px;
  cursor: move;
  border: 1px solid transparent;
  border-radius: 3px;
  transition: all 0.3s;
}
.form-designer-container-9136076486841527 .content aside.left ul li:hover {
  color: #1890ff;
  border: 1px solid #1890ff;
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 6px #1890ff;
}
.form-designer-container-9136076486841527 .content aside.right {
  height: 100%;
  color: #fff;
  overflow: hidden;
  position: relative;
}
.form-designer-container-9136076486841527 .content aside.right .form-item-properties {
  width: 100%;
  height: 100%;
}
.form-designer-container-9136076486841527 .content aside.right .ant-tabs {
  height: 100%;
}
.form-designer-container-9136076486841527 .content aside.right .ant-tabs .ant-tabs-content {
  height: 100%;
}
.form-designer-container-9136076486841527 .content aside.right .ant-tabs .ant-tabs-content .ant-tabs-tabpane {
  height: calc(100% - 45px);
  overflow: auto;
}
.form-designer-container-9136076486841527 .content aside.right .properties-centent {
  height: 100%;
  overflow: hidden;
  background: #fff;
}
.form-designer-container-9136076486841527 .content aside.right .properties-centent .properties-body {
  overflow: auto;
  height: 100%;
  padding: 8px 16px;
}
.form-designer-container-9136076486841527 .content aside.right .properties-centent .ant-form-item {
  margin-bottom: 0;
  padding: 6px 0;
  border-bottom: 1px solid #ccc;
}
.form-designer-container-9136076486841527 .content aside.right .properties-centent .ant-form-item .ant-form-item-label {
  line-height: 2;
}
.form-designer-container-9136076486841527 .content aside.right .hint-box {
  margin-top: 120px;
  color: #aaa;
}
.form-designer-container-9136076486841527 .content section {
  flex: 1;
  max-width: calc(100% - 270px - 270px);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin: 0 8px 0;
  box-shadow: 0px 0px 1px 1px #ccc;
}
.form-designer-container-9136076486841527 .content section .form-panel {
  height: 100%;
  background: #eee;
  position: relative;
}
.form-designer-container-9136076486841527 .content section .form-panel.no-toolbars-top {
  height: calc(100% - 50px);
}
.form-designer-container-9136076486841527 .content section .form-panel > .hint-text {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #aaa;
  z-index: 16;
}
.form-designer-container-9136076486841527 .content section .form-panel .a-form-box {
  height: 100%;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box {
  height: 100%;
  overflow: auto;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main {
  min-height: 100%;
  padding: 5px;
  position: relative;
  background: #fafafa;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .moving {
  min-height: 35px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0 !important;
  position: relative;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .moving::before {
  content: '';
  height: 5px;
  width: 100%;
  background: #1890ff;
  position: absolute;
  top: 0;
  right: 0;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box {
  position: relative;
  box-sizing: border-box;
  padding: 8px;
  overflow: hidden;
  transition: all 0.3s;
  min-height: 36px;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box:hover {
  background: rgba(24, 144, 255, 0.2);
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box::before {
  content: '';
  height: 5px;
  width: 100%;
  background: #1890ff;
  position: absolute;
  top: 0;
  right: -100%;
  transition: all 0.3s;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box.active {
  background: rgba(24, 144, 255, 0.2);
  outline-offset: 0;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box.active::before {
  right: 0;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box .form-item-box {
  position: relative;
  box-sizing: border-box;
  word-wrap: break-word;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box .form-item-box::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 888;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box .form-item-box .ant-form-item {
  margin: 0;
  padding-bottom: 6px;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box .show-key-box {
  position: absolute;
  bottom: 2px;
  right: 5px;
  font-size: 14px;
  z-index: 999;
  color: #1890ff;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box > .copy,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box > .delete {
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  z-index: 989;
  transition: all 0.3s;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box > .copy.unactivated,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box > .delete.unactivated {
  opacity: 0 !important;
  pointer-events: none;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box > .copy.active,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box > .delete.active {
  opacity: 1 !important;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box > .copy {
  border-radius: 0 0 0 8px;
  right: 30px;
  background: #1890ff;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .drag-move-box > .delete {
  right: 0px;
  background: #1890ff;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box > .batch-label,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box > .batch-label {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 10px;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .draggable-box {
  min-height: 60px;
  width: 100%;
  border: 1px #ccc dashed;
  background: #fff;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .draggable-box .list-main {
  min-height: 60px;
  position: relative;
  border: 1px #ccc dashed;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .draggable-box .list-main .moving,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .draggable-box .list-main .moving {
  width: 175px;
  min-height: 94px;
  display: inline-block;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .draggable-box .list-main > div,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .draggable-box .list-main > div {
  min-width: 185px;
  display: inline-block;
  vertical-align: top;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .draggable-box .list-main > div .ant-form-item > .ant-form-item-label,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .draggable-box .list-main > div .ant-form-item > .ant-form-item-label,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .draggable-box .list-main > div .ant-form-item > .ant-form-item-control-wrapper,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .draggable-box .list-main > div .ant-form-item > .ant-form-item-control-wrapper {
  text-align: left;
  display: block;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .draggable-box .list-main > div .ant-col,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .draggable-box .list-main > div .ant-col {
  width: 100%;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .column-box {
  display: flex;
  width: 100%;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .column-box .check-box {
  width: 80px;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .column-box .draggable-box {
  flex: 1;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .draggable-box .ant-form-item-label {
  display: none !important;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box {
  position: relative;
  box-sizing: border-box;
  padding: 5px;
  background: rgba(152, 103, 247, 0.12);
  width: 100%;
  transition: all 0.3s;
  overflow: hidden;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .form-item-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .form-item-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box .form-item-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box .form-item-box {
  position: relative;
  box-sizing: border-box;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .form-item-box .ant-form-item,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .form-item-box .ant-form-item,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box .form-item-box .ant-form-item,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box .form-item-box .ant-form-item {
  margin: 0;
  padding-bottom: 15px;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .grid-row,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .grid-row,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box .grid-row,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box .grid-row,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .table-layout,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .table-layout,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box .table-layout,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box .table-layout {
  background: rgba(152, 103, 247, 0.12);
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .grid-row .grid-col .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .grid-row .grid-col .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box .grid-row .grid-col .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box .grid-row .grid-col .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .table-layout .grid-col .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .table-layout .grid-col .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box .table-layout .grid-col .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box .table-layout .grid-col .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .grid-row .table-td .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .grid-row .table-td .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box .grid-row .table-td .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box .grid-row .table-td .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .table-layout .table-td .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .table-layout .table-td .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box .table-layout .table-td .draggable-box,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box .table-layout .table-td .draggable-box {
  min-height: 60px;
  min-width: 50px;
  border: 1px #ccc dashed;
  background: #fff;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .grid-row .grid-col .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .grid-row .grid-col .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box .grid-row .grid-col .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box .grid-row .grid-col .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .table-layout .grid-col .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .table-layout .grid-col .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box .table-layout .grid-col .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box .table-layout .grid-col .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .grid-row .table-td .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .grid-row .table-td .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box .grid-row .table-td .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box .grid-row .table-td .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box .table-layout .table-td .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box .table-layout .table-td .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box .table-layout .table-td .draggable-box .list-main,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box .table-layout .table-td .draggable-box .list-main {
  min-height: 60px;
  position: relative;
  border: 1px #ccc dashed;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box::before,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box::before,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box::before,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box::before {
  content: '';
  height: 5px;
  width: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  right: -100%;
  transition: all 0.3s;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box.active,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box.active,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box.active,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box.active {
  background: rgba(152, 103, 247, 0.24);
  outline-offset: 0;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box.active::before,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box.active::before,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box.active::before,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box.active::before {
  background: #9867f7;
  right: 0;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box > .copy,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box > .copy,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box > .copy,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box > .copy,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box > .delete,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box > .delete,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box > .delete,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box > .delete {
  position: absolute;
  top: 0px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  z-index: 989;
  transition: all 0.3s;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box > .copy.unactivated,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box > .copy.unactivated,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box > .copy.unactivated,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box > .copy.unactivated,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box > .delete.unactivated,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box > .delete.unactivated,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box > .delete.unactivated,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box > .delete.unactivated {
  opacity: 0 !important;
  pointer-events: none;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box > .copy.active,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box > .copy.active,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box > .copy.active,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box > .copy.active,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box > .delete.active,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box > .delete.active,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box > .delete.active,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box > .delete.active {
  opacity: 1 !important;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box > .copy,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box > .copy,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box > .copy,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box > .copy {
  border-radius: 0 0 0 8px;
  right: 30px;
  background: #9867f7;
}
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .batch-box > .delete,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .select-input-list-box > .delete,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .grid-box > .delete,
.form-designer-container-9136076486841527 .content section .form-panel .draggable-box .list-main .table-box > .delete {
  right: 0px;
  background: #9867f7;
}
.form-designer-container-9136076486841527 .right-menu {
  width: 160px;
  background: #fff;
  border: 1px solid #ccc;
  position: fixed;
  transition: all 0s;
  box-shadow: 3px 3px 8px #999;
  border-radius: 3px;
  padding: 8px 0;
  z-index: 2199;
}
.form-designer-container-9136076486841527 .right-menu ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.form-designer-container-9136076486841527 .right-menu ul li {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}
.form-designer-container-9136076486841527 .right-menu ul li i {
  margin-right: 4px;
}
.form-designer-container-9136076486841527 .right-menu ul li:hover {
  background: #eee;
}
.form-designer-container-9136076486841527 .kk-checkbox .ant-checkbox-wrapper {
  margin-left: 0 !important;
  margin-right: 8px;
}
.form-designer-container-9136076486841527 .list-enter-active {
  transition: all 0.5s;
}
.form-designer-container-9136076486841527 .list-leave-active {
  transition: all 0.3s;
}
.form-designer-container-9136076486841527 .list-enter,
.form-designer-container-9136076486841527 .list-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}
.form-designer-container-9136076486841527 .list-enter {
  height: 30px;
}
.form-designer-container-9136076486841527 ::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;
  scrollbar-arrow-color: red;
}
.form-designer-container-9136076486841527 ::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
  scrollbar-arrow-color: red;
}
.form-designer-container-9136076486841527 ::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
.form-designer-container-9136076486841527 .ant-form-inline .list-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}
.form-designer-container-9136076486841527 .ant-form-inline .list-main .layout-width {
  width: 100%;
}
.form-designer-container-9136076486841527 .ant-form-inline .ant-form-item-control-wrapper {
  width: 175px !important;
}
.json-box-9136076486841527 {
  height: 570px;
  overflow: auto;
}
.json-box-9136076486841527 .vue-codemirror-wrap {
  height: 100%;
}
.json-box-9136076486841527 .vue-codemirror-wrap .CodeMirror-wrap {
  height: 100%;
  background: #f6f6f6;
}
.json-box-9136076486841527 .vue-codemirror-wrap .CodeMirror-wrap .CodeMirror-scroll {
  height: 100%;
  width: 100%;
}
.json-box-9136076486841527 .vue-codemirror-wrap .CodeMirror-wrap pre.CodeMirror-line,
.json-box-9136076486841527 .vue-codemirror-wrap .CodeMirror-wrap .CodeMirror-linenumber {
  min-height: 21px;
  line-height: 21px;
}
.code-modal-9136076486841527 .ant-modal-body {
  padding: 12px;
}
.copy-btn-box-9136076486841527 {
  padding-top: 8px;
  text-align: center;
}
.copy-btn-box-9136076486841527 .copy-btn {
  margin-right: 8px;
}
.k-form-build-9136076486841527 {
  min-height: 300px;
}
.k-form-build-9136076486841527.ant-form-vertical .ant-form-item-required::before {
  display: inline-block;
}
.k-form-build-9136076486841527 .select-input-list-box {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
}
.k-form-build-9136076486841527 .select-input-list-box .ant-form-item {
  margin-bottom: 2px;
  margin-right: 8px;
}
.k-form-build-9136076486841527 .select-input-list-box.ant-form-horizontal .ant-col.ant-form-item-control-wrapper {
  height: 30px;
  display: flex;
  align-items: center;
}
.k-form-build-9136076486841527 .select-input-list-box.ant-form-horizontal .text-box {
  height: 30px;
  line-height: 30px;
}
.k-form-build-9136076486841527 .ant-form-item {
  margin-bottom: 16px;
}
.k-form-build-9136076486841527 .ant-form-item-control {
  position: relative;
}
.k-form-build-9136076486841527 .ant-form-item-control .ant-form-explain {
  bottom: -16px;
  position: absolute;
  z-index: 10;
}
.k-form-build-9136076486841527.ant-form-inline .ant-form-item-control-wrapper {
  width: 175px !important;
}
.k-form-build-9136076486841527 .batch-table .ant-form-item {
  margin: 0;
}
.k-form-build-9136076486841527 .batch-table .ant-form-item-control-wrapper {
  width: 170px !important;
}
.k-form-build-9136076486841527 .batch-table .ant-table-thead > tr > th {
  padding: 12px;
}
.k-form-build-9136076486841527 .batch-table .ant-table-tbody > tr > td {
  padding: 8px;
}
.k-form-build-9136076486841527 .batch-table .ant-form-inline .ant-form-item-with-help {
  margin: 0;
}
.k-form-build-9136076486841527 .batch-table .ant-table-placeholder {
  padding: 0;
}
.k-form-build-9136076486841527 .hide-upload-drag .ant-upload.ant-upload-drag {
  display: none;
}
.m-colorPicker {
  width: 25%;
  vertical-align: bottom;
  padding: 5px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  font-size: 0;
}
.m-colorPicker .colorBtn {
  height: 22px !important;
  width: 100% !important;
}
