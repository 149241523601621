/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
.avatar {
  background: transparent;
  border: 1px solid #606266;
  color: #606266;
  transition: all 0.2s;
}
.ant-menu-submenu-active .avatar {
  border-color: #1890ff;
  color: #1890ff;
}
