/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
#NavStandard .nav {
  height: 100%;
  background: #fff;
}
#NavStandard .primary-logo {
  width: 100%;
  height: 48px;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 24px;
  color: #303133;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  background: #fff;
}
#NavStandard .primary-logo img {
  border: 0;
  width: 100%;
  height: 40px;
  -o-object-fit: scale-down;
     object-fit: scale-down;
}
#NavStandard .menu {
  height: 100%;
  padding-bottom: 50px;
  overflow: hidden;
}
#NavStandard .menu *::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 10px;
}
#NavStandard .menu *::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: #c0c4cc;
}
#NavStandard .menu *::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset -1px 0 0 0 #ebeef5, inset 1px 0 0 0 #ebeef5;
  background: #ebeef5;
}
#NavStandard .menu *::-webkit-scrollbar-thumb:hover {
  /*滚动条里面轨道*/
  background: #606266;
}
#NavStandard .menu .title {
  position: relative;
  min-width: 100px;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
#NavStandard .menu:hover {
  overflow-y: auto !important;
}
#NavStandard .ant-menu-inline .ant-menu-item,
#NavStandard .ant-menu-inline .ant-menu-submenu-title {
  /* width: calc(100% + 1px); */
  width: 100%;
}
#NavStandard .ant-menu-inline-collapsed {
  width: 48px;
}
#NavStandard .ant-menu-inline-collapsed > .ant-menu-item,
#NavStandard .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
#NavStandard .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
#NavStandard .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 16px !important;
}
#NavStandard .ant-menu-sub {
  padding-left: 10px !important;
}
#NavStandard .sider {
  height: 100%;
  box-shadow: none;
  z-index: 999;
}
#NavStandard .menu {
  z-index: 999;
  position: relative;
  box-shadow: 2px 0 8px 0 rgba(0, 0, 0, 0.08);
}
#NavStandard .ant-menu-root.ant-menu-vertical,
#NavStandard .ant-menu-root.ant-menu-vertical-left,
#NavStandard .ant-menu-root.ant-menu-vertical-right,
#NavStandard .ant-menu-root.ant-menu-inline {
  border: 0;
}
.ant-menu-inline-collapsed-tooltip img {
  display: none;
}
.ant-menu-inline-collapsed-tooltip span {
  margin-left: 0;
}
.ant-menu-inline-collapsed-tooltip .ant-tooltip-arrow::before {
  background: #303133;
}
.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner {
  color: #fff;
  background: #303133;
  box-shadow: 0px 2px 8px 0px rgba(172, 172, 172, 0.5);
}
.ant-tooltip .avatar {
  display: none;
  margin-right: -20px;
}
