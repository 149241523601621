/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
#Toolbar {
  padding: 0;
  height: 50px !important;
  background-color: #001528;
  z-index: 12;
}
#Toolbar .menu-icon {
  width: 50px;
  height: 50px;
  position: relative;
}
#Toolbar .menu-icon:hover {
  background-color: #40a9ff !important;
}
#Toolbar .menu-icon .icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
#Toolbar .ant-badge:not(.ant-badge-status) {
  margin-right: 20px;
}
#Toolbar .bell {
  font-size: 20px;
}
#Toolbar .switch-btn {
  height: 24px;
  border: 1px solid #3b97fe;
  /*字体 主体色*/
  /*border 主体色*/
  border-radius: 2px;
}
[data-theme='theme'] #Toolbar .switch-btn {
  color: #1890ff;
}
[data-theme='theme1'] #Toolbar .switch-btn {
  color: #3b97fe;
}
[data-theme='theme2'] #Toolbar .switch-btn {
  color: #1173ce;
}
[data-theme='theme3'] #Toolbar .switch-btn {
  color: #212122;
}
[data-theme='theme4'] #Toolbar .switch-btn {
  color: #009b94;
}
[data-theme='theme5'] #Toolbar .switch-btn {
  color: #2f54eb;
}
[data-theme='theme'] #Toolbar .switch-btn {
  border-color: #1890ff;
}
[data-theme='theme1'] #Toolbar .switch-btn {
  border-color: #3b97fe;
}
[data-theme='theme2'] #Toolbar .switch-btn {
  border-color: #1173ce;
}
[data-theme='theme3'] #Toolbar .switch-btn {
  border-color: #212122;
}
[data-theme='theme4'] #Toolbar .switch-btn {
  border-color: #009b94;
}
[data-theme='theme5'] #Toolbar .switch-btn {
  border-color: #2f54eb;
}
#Toolbar .workbench {
  width: 82px;
  height: 30px;
  line-height: 30px;
}
#Toolbar .workbench .ant-btn {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.8);
  border: none;
  font-size: 14px;
  padding: 0 8px;
}
#Toolbar .workbench .ant-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}
#Toolbar .organize {
  transition: all 0.2s;
}
#Toolbar .toggleSwitch {
  height: 100%;
  transition: all 0.5s;
}
#Toolbar .toggleSwitch:hover {
  background: rgba(255, 255, 255, 0.12);
}
