/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
#UserInfo .profilePhoto .ant-upload.ant-upload-select-picture-card > .ant-upload {
  border-radius: 50px;
}
#UserInfo .profilePhoto .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: 100px;
  height: 100px;
}
#UserInfo .croppa-container {
  border-radius: 50%;
}
.userInfo .ant-modal-header {
  height: 170px;
  background: url(../img/userInfo-bg.596541cc.jpg) no-repeat;
  opacity: 0.9;
  background-size: 100% 100%;
  color: #fff;
}
.userInfo .ant-modal-title,
.userInfo .ant-modal-close {
  color: #fff;
}
.userInfo .profile {
  position: absolute;
  left: 0;
  right: 0;
  top: -105px;
  margin: 0 auto;
  width: 150px;
  height: 150px;
  padding: 5px;
  background: #eee;
  border-radius: 75px;
  overflow: hidden;
}
.userInfo .profile img {
  width: 140px;
  height: 140px;
  border-radius: 70px;
}
#UserInfo-Modal .ant-modal-body {
  overflow: visible;
}
