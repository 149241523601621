/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
#DrawerLeft {
  width: 208px;
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
}
#DrawerLeft .menu {
  /*height: 48px;*/
  border-right: none;
}
#DrawerLeft .menu .ant-menu-item {
  height: 48px;
  line-height: 48px;
  margin: 0;
}
#DrawerLeft .menu-top {
  background: #f5f5f7;
}
#DrawerLeft .menu-bottom .ant-menu-item {
  border-top: 1px solid #ddd;
}
#DrawerLeft .ant-menu-item-disabled {
  background: #f5f5f7;
  color: #606266 !important;
}
#DrawerLeft .ant-menu-item-disabled .anticon-right {
  line-height: 48px !important;
  font-size: 12px;
}
