/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
.upload-img-box-9136076486841527 {
  /* you can make up upload button and sample style by using stylesheets */
}
.upload-img-box-9136076486841527 .ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.upload-img-box-9136076486841527 .ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
