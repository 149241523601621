/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
.v-loading-box {
  background: #f5f5f5;
  flex: 1;
  -webkit-flex: 1;
  height: 100%;
}
.v-ajax-loading {
  display: flex;
  -webkit-display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.head-space {
  height: 4.4rem;
}
.v-loading-box {
  color: #f5514d !important;
}
.v-ajax-loading .v-la-square-jelly-box {
  margin: -16px auto 0;
  position: relative;
  top: 50%;
}
.v-la-square-jelly-box,
.v-la-square-jelly-box > div {
  position: relative;
  box-sizing: border-box;
}
.v-la-square-jelly-box {
  display: block;
  font-size: 0;
  color: #fff;
}
.v-la-square-jelly-box.la-dark {
  color: #333;
}
.v-la-square-jelly-box > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}
.v-la-square-jelly-box {
  width: 40px;
  height: 40px;
}
.v-la-square-jelly-box > div:nth-child(1),
.v-la-square-jelly-box > div:nth-child(2) {
  position: absolute;
  left: 0;
  width: 100%;
}
.v-la-square-jelly-box > div:nth-child(1) {
  top: -25%;
  z-index: 1;
  height: 100%;
  border-radius: 10%;
  animation: v-square-jelly-box-animate 0.6s -0.1s linear infinite;
}
.v-la-square-jelly-box > div:nth-child(2) {
  bottom: -9%;
  height: 10%;
  background: #000;
  border-radius: 50%;
  opacity: 0.2;
  animation: v-square-jelly-box-shadow 0.6s -0.1s linear infinite;
}
.v-la-square-jelly-box.la-sm {
  width: 16px;
  height: 16px;
}
.v-la-square-jelly-box.la-2x {
  width: 64px;
  height: 64px;
}
.v-la-square-jelly-box.la-3x {
  width: 96px;
  height: 96px;
}
@keyframes v-square-jelly-box-animate {
  17% {
    border-bottom-right-radius: 10%;
  }
  25% {
    transform: translateY(25%) rotate(22.5deg);
  }
  50% {
    border-bottom-right-radius: 100%;
    transform: translateY(50%) scale(1, 0.9) rotate(45deg);
  }
  75% {
    transform: translateY(25%) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}
@keyframes v-square-jelly-box-shadow {
  50% {
    transform: scale(1.25, 1);
  }
}
