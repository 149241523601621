/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
#Layout {
  height: 100%;
}
#Layout .imgEle {
  margin-top: 250px;
  width: 160px;
  height: 160px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
#Layout .a-layout-content {
  position: relative;
  height: 100%;
  overflow-x: auto;
}
#Layout .a-layout-content > div {
  height: 100%;
}
#Layout .slide-fade-enter-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  transform-origin: top;
}
#Layout .slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  transform-origin: top;
}
#Layout .slide-fade-enter,
#Layout .slide-fade-leave-to {
  /*// transform: translateX(200px);*/
  /*transform: scale(0, 0.2);*/
  opacity: 0;
}
#Layout .menuDrawer .ant-drawer-content-wrapper {
  width: auto !important;
}
