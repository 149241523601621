/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
#FileUpload {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 99999;
  width: 800px;
  background: #fff;
}
.updateList {
  border-radius: 4px;
  padding: 16px 18px;
  transition: all 0.4s;
}
.updateList .ant-notification-notice-icon {
  line-height: 15px;
}
.updateList .ant-notification-notice-with-icon .ant-notification-notice-message {
  width: calc(100% - 29px);
  margin-left: 29px;
  margin-bottom: 0;
  padding-right: 0;
}
.updateList .ant-notification-notice-close {
  display: none;
}
.ant-notification {
  bottom: 50px !important;
}
