/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
.slider-box[data-v-ff43637c] {
  display: flex;
}
.slider-box > .slider[data-v-ff43637c] {
  flex: 1;
  margin-right: 16px;
}
.slider-box > .number[data-v-ff43637c] {
  width: 70px;
}
