/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
#Title {
  position: relative;
  z-index: 1;
  background: #fff;
}
#Title .title {
  /*font-size:;*/
}
#Title .back-btn {
  /*字体 主体色*/
  font-size: 24px;
  margin-right: 5px;
  cursor: pointer;
  line-height: 24px;
}
[data-theme='theme'] #Title .back-btn {
  color: #1890ff;
}
[data-theme='theme1'] #Title .back-btn {
  color: #3b97fe;
}
[data-theme='theme2'] #Title .back-btn {
  color: #1173ce;
}
[data-theme='theme3'] #Title .back-btn {
  color: #212122;
}
[data-theme='theme4'] #Title .back-btn {
  color: #009b94;
}
[data-theme='theme5'] #Title .back-btn {
  color: #2f54eb;
}
#Title .title-card {
  min-height: 48px;
  padding: 0 24px;
}
#Title .ant-tabs-bar {
  border: 0;
}
#Title:before,
#Title:after {
  content: '';
  display: table;
}
#Title .publicTitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
