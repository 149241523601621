
.dynamic-opr-button[data-v-741f4f8c]:last {
  margin-left: 0px;
}
.dynamic-opr-button[data-v-741f4f8c] {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 16px;
  color: #999;
  transition: all 0.3s;
  margin-left: 6px;
}
.dynamic-opr-button[data-v-741f4f8c]:hover {
  color: #e89;
}
.dynamic-opr-button[disabled][data-v-741f4f8c] {
  cursor: not-allowed;
  opacity: 0.5;
}
