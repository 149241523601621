/* 布局
----------*/
.d-block {
  display: block;
}

.inline-flex {
  display: inline-flex;
}

.d-inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-stretch {
  align-items: stretch;
}

.align-self-start {
  align-self: start;
}

.align-self-end {
  align-self: end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-auto {
  align-self: auto;
}

.align-self-stretch {
  align-self: stretch;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.align-content-start {
  align-content: start;
}

.align-content-end {
  align-content: end;
}

.align-content-center {
  align-content: center;
}

.align-content-space-between {
  align-content: space-between;
}

.align-content-space-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.flex-item {
  flex: 1;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-grow-0 {
}

.flex-grow-1 {
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.pointer {
  cursor: pointer;
}

svg path {
  fill: currentColor;
}

/*
超长省略号 ---------------*/
.over {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multi-over {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 20px;
}

/* 定位
--------- */
.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.pos-fixed {
  position: fixed;
}

/* 宽高100%
-----------*/
.fill-width {
  width: 100%;
}

.fill-height {
  height: 100%;
}

span.fileDownLoad,
a.fileDownLoad {
  cursor: pointer;
  color: rgb(61, 168, 245);
}

input[readonly] {
  cursor: auto;
}

.tag-blue {
  background-color: rgb(61, 168, 245);
}

.tag-green {
  background-color: rgb(117, 201, 64);
}

.tag-cyan {
  background-color: rgb(47, 189, 179);
}

.tag-purple {
  background-color: rgb(121, 126, 201);
}

.tag-yellow {
  background-color: rgb(255, 175, 56);
}

.tag-red {
  background-color: rgb(255, 79, 62);
}

.form-value {
  color: #111;
  font-size: 16px;
  line-height: 20px;
}

.p-t-50 {
  padding-top: 50px;
}

.layout-1 {
  height: calc(100vh - 48px);
  overflow: auto;
}

.layout-1:hover {
  overflow: auto;
}

.p-t-90 {
  padding-top: 90px;
}

.layout-2 {
  height: 100%;
  overflow: auto;
}

/* .layout-2 .ant-card-body .ant-table-content {
  height: calc(100vh - 420px);
  overflow: auto;
} */

.layout-2:hover {
  overflow: auto;
}

.search {
  width: 260px;
}
.cursor {
  cursor: pointer;
}

.layout-3 {
  height: calc(100vh - 48px);
  overflow: auto;
  padding-bottom: 24px;
}

.layout-3:hover {
  overflow: auto;
}

.layout-4 {
  height: calc(100vh - 185px);
  overflow: auto;
  padding-bottom: 80px;
}

.layout-4:hover {
  overflow: auto;
}
/* 筛选抽屉footer按钮样式 */
.drawer-screen-footer-button {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ccc;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}

/* ---------------------- */
/* 删除头像时的样式 */

.avatar:hover .delete {
  opacity: 1;
  z-index: 99;
}

.avatar .delete {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  z-index: -1;
  transition: all 0.2s linear;
}

.avatar .delete i {
  margin-top: 50%;
  transform: translate(0px, -50%);
}

/* ---------------------- */
@font-face {
  /*DINOT*/
  font-family: 'DINOT';
  src: url(../fonts/DINPro-Medium.48a3635c.otf);
  font-weight: normal;
  font-style: normal;
}

@keyframes enter-x-animation {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes enter-y-animation {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
