/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
#Progress .text {
  font-size: 14px;
  color: #303133;
  line-height: 22px;
  cursor: pointer;
}
#Progress .ant-progress {
  position: absolute;
  left: 0;
  bottom: -8px;
}
#Progress .ant-progress .ant-progress-inner,
#Progress .ant-progress .ant-progress-bg {
  border-radius: 0 !important;
}
#Progress .list {
  width: calc(100% + 105px);
  position: relative;
  max-height: 320px;
  min-height: 64px;
  overflow-y: auto;
  overflow-x: hidden;
  left: -87px;
  margin-bottom: -8px;
}
#Progress .list li {
  height: 64px;
  display: flex;
  position: relative;
  padding: 14px 18px 10px 22px;
  z-index: 9;
  overflow: hidden;
}
#Progress .list li > img {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}
#Progress .list li .content {
  flex: 1;
  width: 240px;
}
#Progress .list li .content > div:first-child {
  font-size: 14px;
  color: #303133;
}
#Progress .list li .content > div:last-child {
  font-size: 12px;
  color: #909399;
}
#Progress .list li .actions {
  width: 44px;
}
#Progress .list li .bg-progress {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #f5f5f5;
  z-index: -1;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
#Progress .imgs {
  position: absolute;
  top: 18px;
  right: 18px;
  color: #606266;
  outline: none;
}
#Progress .imgs > .icon {
  font-size: 16px;
  cursor: pointer;
}
#Progress .imgs > .icon:last-child {
  margin-left: 12px;
}
#Progress .imgs > .icon:hover {
  /*字体 主体色*/
}
[data-theme='theme'] #Progress .imgs > .icon:hover {
  color: #1890ff;
}
[data-theme='theme1'] #Progress .imgs > .icon:hover {
  color: #3b97fe;
}
[data-theme='theme2'] #Progress .imgs > .icon:hover {
  color: #1173ce;
}
[data-theme='theme3'] #Progress .imgs > .icon:hover {
  color: #212122;
}
[data-theme='theme4'] #Progress .imgs > .icon:hover {
  color: #009b94;
}
[data-theme='theme5'] #Progress .imgs > .icon:hover {
  color: #2f54eb;
}
#Progress .imgs > .icon.close:hover {
  color: #ee4f61;
}
#Progress::before {
  width: 100%;
  height: 1px;
  content: '';
  position: absolute;
  left: 0;
  top: 56px;
  background: #f5f5f5;
}
