/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
#AppendixUpload .uploadFile {
  display: inline-block;
  line-height: 32px;
  width: 110px;
  height: 32px;
  padding: 0 15px;
  margin-bottom: 8px;
  border: 0.5px solid #ccc;
  cursor: pointer;
}
#AppendixUpload .uploadFile:hover {
  color: #40a9ff;
  border-color: #40a9ff;
}
.uploadList .fileName:hover {
  color: #1890ff;
}
