/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
.ant-dropdown-menu-item-active1 {
  background: #fff6f0;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f3f3f3;
}
#UserMenu {
  height: 100%;
}
#UserMenu .pointer {
  transition: all 0.5s;
}
#UserMenu .pointer:hover {
  background: rgba(255, 255, 255, 0.12);
}
#UserMenu .user-wrapper {
  height: 100%;
}
#UserMenu .user-wrapper .content-box {
  height: 100%;
}
#UserMenu .user-wrapper .content-box .ant-dropdown-trigger {
  height: 100%;
}
#UserMenu .nick {
  max-width: 70px;
  margin-right: 0;
  line-height: 48px;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
