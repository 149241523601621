.ant-tabs-nav .ant-tabs-tab {
  margin-right: -1px;
  font-size: 16px;
  transition: none !important;
}

#Title .ant-tabs-bar {
  /*margin-left: -16px;*/
}

.ant-tabs-bar {
  margin-bottom: 1px;
  /* border: 0; */
}

.ant-tabs-top .ant-tabs-ink-bar-animated,
.ant-tabs-bottom .ant-tabs-ink-bar-animated {
  transition: none !important;
}

.ant-card {
  border: none;
  /* box-shadow: 0px 2px 4px 0px rgba(133, 133, 133, 0.2); */
}

.ant-card-wider-padding .ant-card-body {
  /* padding: 20px; */
}

/*
.table-card .ant-card-head {
  border: 0;
  margin-bottom: -8px;
}
.table-card .ant-card-head .ant-card-head-wrapper {
  display: flex;
  justify-content: space-between;
}

.table-card .ant-card-head-title,
.table-card .ant-card-extra {
  padding-bottom: 0;
}
*/

.ant-card-head {
  border: 0;
}

.ant-card-head .ant-card-head-wrapper {
  display: flex;
  justify-content: space-between;
}

.ant-card-head-title {
}

.ant-card-body {
  /* padding: 20px; */
}

.ant-table .ant-table-thead tr th {
  border-color: #ebeef5;
  font-weight: 600;
}

.ant-table-empty .ant-table-body {
  overflow: hidden !important;
}

.ant-table-empty .ant-table-body:hover {
  overflow: hidden !important;
}

.ant-table-fixed-left {
  /* pointer-events: none; */
}

.ant-checkbox-input,
.icon-btn {
  /* pointer-events: auto; */
}

.ant-table-fixed-left
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-tbody
  tr:nth-last-child(1)
  td,
.ant-table-fixed-right
  > .ant-table-body-outer
  > .ant-table-body-inner
  > table
  > .ant-table-tbody
  tr:nth-last-child(1)
  td,
.ant-table-body tr:nth-last-child(1) td {
  /* border: 0 !important; */
}

.ant-table-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-top: none; */
  color: #b4b8bd;
  border: none;
  margin-top: 0;
  margin-bottom: 24px;
}

.ant-table-placeholder::before {
  content: '';
  display: block;
  width: 64px;
  height: 64px;
  background: url(../img/no-data-default.2ae6091d.png) no-repeat;
  background-size: 100% 100%;
  margin: 20px 0 0px;
}

.ant-table-footer {
  background-color: #fff;
}

.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-bottom: 5px;
  margin-top: 5px;
}

.custom-filter-dropdown {
  padding: 8px 8px 0;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  max-width: 400px;
  min-width: 200px;
}

.custom-filter-dropdown .ant-checkbox-wrapper {
  margin-bottom: 8px;
}

.ant-input-search-enter-button .ant-input-group-addon {
  padding: 0;
  border: 0;
}

.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-right: 1px solid transparent;
}

.ant-input-search-enter-button .ant-input-group-addon .ant-input-search-button {
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-fullcalendar-date-panel {
  position: relative;
  outline: none;
}

.ant-fullcalendar-calendar-body {
  padding: 8px 12px;
}

/* 麵包屑*/
.breadcrumb-container .breadcrumb {
  display: inline-block;
  position: relative;
  padding: 0px 20px;
  height: 30px;
  line-height: 30px;
  background: #f1f4f9;
}

.breadcrumb-container .breadcrumb:after {
  content: '';
  display: block;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid #f1f4f9;
  position: absolute;
  right: -15px;
  top: 0;
}

.breadcrumb-container .breadcrumb:before {
  content: '';
  display: block;
  border-top: 15px solid #f1f4f9;
  border-bottom: 15px solid #f1f4f9;
  border-left: 15px solid transparent;
  position: absolute;
  left: -15px;
  top: 0;
}

.breadcrumb-container .breadcrumb:first-child:before {
  left: 0px;
}

.ant-form-item-required::before {
  display: none;
}

.ant-form-item-required::after {
  display: inline-block;
  margin-left: 4px;
  color: #f5222d;
  font-size: 12px;
  /* font-family: SimSun, sans-serif; */
  line-height: 0px;
  content: '*' !important;
  vertical-align: 3px;
  top: 4px !important;
}

/*
.form .ant-form-item {
  margin-bottom: 0;
}

.form .ant-form-item label {
  margin-bottom: 8px;
}
*/
/* .form .ant-form-item-control {
  line-height: 40px;
  min-height: 52px;
}

.ant-modal-body .ant-form-explain {
  -line-height: unset;
} */
/* .ant-form-explain {
  /* font-size: 14px; */
/* margin-top: 0;
  min-height: 14px; */
/* line-height: 1.5;
} */

form .ant-mentions,
form textarea.ant-input,
.ant-form-item-with-help {
  -margin-bottom: 0;
}

.ant-modal .ant-modal-body {
  max-height: 592px;
  overflow: auto;
  padding: 24px 48px;
}

.ant-modal-header .ant-modal-title {
  font-size: 18px;
  font-weight: 600;
}

.ant-checkbox-group label,
.ant-radio-group label {
  /* font-weight: 500; */
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:active,
.ant-select-selection:focus,
.ant-select-open .ant-select-selection {
  box-shadow: none !important;
}

.ant-select-selection--multiple {
  padding-bottom: 0;
}

.ant-divider {
  border-color: rgba(235, 238, 245, 1);
}

.ant-divider-horizontal.ant-divider-with-text-right::before,
.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 0%;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 0 0 0;
}

/* .ant-breadcrumb-separator {
  margin: 0 4px;
} */
select::-webkit-input-placeholder {
  color: #c0c4cc;
}

select::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c0c4cc;
}

select:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c0c4cc;
}

select:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c0c4cc;
}

input::-webkit-input-placeholder {
  color: #c0c4cc;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c0c4cc;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c0c4cc;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c0c4cc;
}

.ant-tooltip-inner {
  color: #330033;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2px 10px 0px rgba(48, 48, 48, 0.2);
}

.ant-tooltip-arrow::before {
  background: rgba(255, 255, 255, 0.9);
}

.affix-box {
  width: 100%;
  box-shadow: 0px -3px 12px 0px rgba(0, 0, 0, 0.08);
  position: absolute;
  bottom: 0px;
}

/* 处理固钉当无按钮撑开时无法贴底展示 */
.affix-box .bg-white {
  height: 48px;
}

/* ---------------------- */
/* 首页及大数据及看板的表格样式高默认40 */

.table-bi-height .ant-table-tbody > tr > td {
  padding: 9.5px 16px !important;
}

.table-bi-height .ant-table-thead tr:nth-of-type(1) th {
  padding: 8px 16px !important;
}
/*重置公共弹窗样式*/
.special-modal.ant-modal {
  padding: 0;
  top: 100px;
}
.special-modal.ant-modal .ant-modal-body {
  max-height: calc(100vh - 308px) !important;
  min-height: calc(400px - 108px) !important;
  padding: 24px 24px 0 24px !important;
  position: relative;
  overflow: hidden !important;
}
/**********************/
