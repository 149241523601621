/*字体颜色*/
/*边框颜色*/
/*背景色*/
/*字体大小*/
/*阿里字体图标大小*/
/*字体行高*/
/*字体font-weight*/
/*辅助色/功能色 - 常规*/
/*功能色*/
/*辅助色/功能色 - 浅色*/
/**/
/*modalMaxHeight*/
/**
 *主题
 */
/*theme1*/
/*theme2*/
/*theme3*/
/*theme4*/
/*theme5*/
#LastActivity {
  padding: 16px;
  flex: 1;
}
#LastActivity .title {
  font-size: 12px;
  color: #303133;
  line-height: 20px;
  font-weight: 800;
  margin-bottom: 18px;
}
#LastActivity ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#LastActivity ul > li {
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  color: #606266;
  cursor: pointer;
  display: flex;
  align-items: center;
}
#LastActivity ul > li > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#LastActivity ul > li .notAccessible {
  color: #ccc;
  cursor: no-drop;
}
#LastActivity ul > li .accessible:hover .avatar {
  border-color: #1890ff;
  color: #1890ff;
}
#LastActivity ul > li .accessible:hover {
  color: #1890ff;
}
#LastActivity .guess {
  font-size: 12px;
  text-align: center;
  color: #ccc;
  margin-top: 50px;
}
